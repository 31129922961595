import React from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import theme from '../utils/theme'
import { rhythm } from '../utils/typography'

import Layout from '../components/Layout'
import Page from '../components/ui/Page'
import SplitContainer from '../components/ui/SplitContainer'
import { EventCards as EventsHappeningSoon } from '../components/EventsHappeningSoon'

import sideimage from './home/images/emile-seguin-unsplash.jpg'

const { Paragraph: P } = SplitContainer

const SectionHeader = styled.div`
  margin-bottom: ${rhythm(2)};
`

const EventsHeader = styled.h1`
  color: ${theme.colors.selection};
`

const Header = styled.h3`
  color: ${theme.colors.primaryFont};
`

const NotFoundPage = () => (
  <Layout>
    <Page>
      <SplitContainer imageUrl={sideimage}>
        <Helmet title={`404 page`} />
        <SectionHeader>
          <EventsHeader>404 Error</EventsHeader>
          <P>
            Oh no! We're sorry, this page doesn't exist. In the meantime, check
            out all these dope events happening soon in Park City.
          </P>
        </SectionHeader>
        <Header>Events happening soon</Header>
        <EventsHappeningSoon eventCardHorizontal={true} />
      </SplitContainer>
    </Page>
  </Layout>
)

export default NotFoundPage
